import { Box, Balance, Flex, Text, Td, ScanLink } from '@pancakeswap/uikit'
import { userData } from 'views/Leaderboard/types'
import BoostedTag from './NFTBoostedTag'
import { useSpecificNFTBalance } from 'hooks/useNFTBalance'
import { Address } from 'viem'
import { getBlockExploreLink } from 'utils'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useCroId from 'hooks/useCronosID'

interface MyRankProps {
  data: userData
  address: Address
}

const MyRank: React.FC<React.PropsWithChildren<MyRankProps>> = ({ data, address }) => {
  const { chainId } = useActiveChainId()

  const {shortEnsName} = useCroId(address, chainId);  const { hasNFT } = useSpecificNFTBalance("0x1dba58b1bdcaa3b0488065ccefa057c52a963280", address)
  return (
    <tr style={{
      backgroundImage:'linear-gradient(90deg, rgba(0, 255, 255, 0) 0%, rgb(56, 144, 230) 50%, rgba(0, 255, 255, 0) 100%)'
    }}>
      <Td textAlign="left">
        <Flex>
          <Text bold mr="4px" width="25px" color="text" style={{ alignSelf: 'center' }}>
            {data.rank} 
          </Text>
          <Text style={{ alignSelf: 'center' }} bold ml="8px">
            <ScanLink displayIcon={false}
              color="white"
              fontSize={'12px'} 
              chainId={chainId}
              href={getBlockExploreLink(address, 'address', chainId)}
            >
              {shortEnsName || truncateHash(address)}
            </ScanLink>
          </Text>
          <Text style={{ alignSelf: 'center' }} color="white" bold ml="4px">
            (You)
          </Text>
        </Flex>
      </Td>
      <Td textAlign="left">
        <Box display="flex" style={{ alignItems: 'center', justifyContent: 'right', gap: 5 }}>
          <Balance
            fontSize="16px"
            color={data.points > 0 ? 'text' : 'textSubtle'}
            textAlign={"right"}
            decimals={0}
            value={Number(data.points)}
          />
          {hasNFT ? <BoostedTag /> : <Flex style={{ width: '12px' }} />}
        </Box>
      </Td>
    </tr>
  )
}

export default MyRank
