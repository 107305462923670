import React from 'react'
import { styled } from "styled-components";
import {
  Flex,
  RocketIcon,
  useTooltip,
} from '@pancakeswap/uikit'

const Wrapper = styled.div`
  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;

const NFTBoostedTag = () => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip('NFT Boost');
  return (
    <Flex alignItems="center" ref={targetRef}>
      {tooltipVisible && tooltip}
      <Wrapper as={Flex} alignItems="center">
          <RocketIcon width="12px" color="success"/>
      </Wrapper>
    </Flex>
  )
}

export default NFTBoostedTag
