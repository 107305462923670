import { Card, Table, Th, Td, CrownIcon, Flex } from '@pancakeswap/uikit';
import { useTranslation } from '@pancakeswap/localization';
import RankCard from './RankCard';
import MyRank from './MyRank';
import { userData } from 'views/Leaderboard/types';
import { useAccount } from 'wagmi';

interface TraderData {
  address: string;
  points: number;
  rank: number;
}

interface TradersProps {
  data: TraderData[];
  userData?: userData;
  isLoading: boolean;
}

const Traders: React.FC<React.PropsWithChildren<TradersProps>> = ({
  data,
  userData,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  // Check if the userData address is present in the top ranks
  const isUserInTopRanks = data.some((rank) => rank.address === address);

  return (
    <Card isActive>
      <Table>
        <thead>
          <tr>
            <Th textAlign="left" style={{ color: 'white' }}>
              <Flex alignItems={'center'} gap="5px">
                {t('Rank (Top 10 users)')}
                <CrownIcon style={{ marginTop: '-5px' }} />
              </Flex>
            </Th>
            <Th textAlign="right" style={{ paddingRight: '30px', color: 'white' }}>{t('Points')}</Th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <Td colSpan={3} textAlign="center">
                {t('Loading...')}
              </Td>
            </tr>
          ) : (
            <>
              {!data || data.length === 0 ? (
                <tr>
                  <Td colSpan={3} textAlign="center">
                    {t('No results')}
                  </Td>
                </tr>
              ) : (
                <>
                  {/* Display MyRank on top if the user is not in the top ranks */}
                  {!isUserInTopRanks && userData && (
                    <MyRank data={userData} address={address} />
                  )}
                  {data.map((rank) =>
                    rank.address === address ? (
                      <MyRank key={rank.rank} data={userData} address={address} />
                    ) : (
                      <RankCard key={rank.rank} rank={rank} />
                    )
                  )}
                </>
              )}
            </>
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default Traders;