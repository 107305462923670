import useSWR from 'swr'
import { Currency } from '@pancakeswap/sdk'

interface TokenBoostResponse {
  tokens: {
    currency: Currency
    boost: number
  }[]
}

const useTokenBoosts = (chainId: number) => {
  const fetchTokenBoosts = async () => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_REWARDS_API}/${chainId}/v1/token-boosts`)
    if (!response.ok) {
      throw new Error('Failed to fetch token boosts')
    }
    return response.json() as Promise<TokenBoostResponse>
  }

  const { data, error } = useSWR(
    chainId ? [`/token-boosts/${chainId}`] : null,
    () => fetchTokenBoosts(),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    }
  )

  return {
    boosts: data?.tokens ?? [],
    isLoading: !error && !data,
    isError: error,
  }
}

export default useTokenBoosts