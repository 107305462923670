import { Text, Flex, Td, Box, ScanLink, Balance } from '@pancakeswap/uikit'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { getBlockExploreLink } from 'utils';
import useCroId from 'hooks/useCronosID';
import { Address } from 'viem';
import NFTBoostedTag from './NFTBoostedTag';
import { useSpecificNFTBalance } from 'hooks/useNFTBalance';

interface TraderData {
  address: string;
  points: number;
  rank: number;
}

interface RankCardProps {
  rank: TraderData
  prizes?: number[]
}

const RankCard: React.FC<React.PropsWithChildren<RankCardProps>> = ({ rank, prizes }) => {
  const { chainId } = useActiveChainId()
  const profile = undefined
  // const isProfileLoading = false
  const {shortEnsName} = useCroId(rank.address as Address, chainId);
  const { hasNFT:robinNFT } = useSpecificNFTBalance("0x1dba58b1bdcaa3b0488065ccefa057c52a963280", rank.address as Address);
  const { hasNFT:CronosCruiser } = useSpecificNFTBalance("0xd25358e2cAD3E1Fd165887569892A99fFFA674ac", rank.address as Address);
  const hasNFT = robinNFT || CronosCruiser;
  // const { domainName } = useDomainNameForAddress(rank.address, !profile && !isProfileLoading)
  return (
    <tr>
      <Td textAlign="left">
        <Flex>
          <Text bold mr="4px" width="25px" color="text" style={{ alignSelf: 'center' }}>
            {rank.rank === 0 ? '--' : rank.rank >= 1000 ? `>1000` : `${rank.rank}`}
          </Text>
          <Text style={{ alignSelf: 'center' }} color="primary" bold ml="8px">
            <ScanLink displayIcon={false}
              chainId={chainId}
              href={getBlockExploreLink(rank.address, 'address', chainId)}
            >
              {profile?.username || shortEnsName || truncateHash(rank.address)}
            </ScanLink>
          </Text>
        </Flex>
      </Td>
      <Td textAlign="left">
        <Box display="flex" style={{ alignItems: 'center', justifyContent: 'right', gap: 5 }}>
          {/*
          <Text color={rank.points > 0 ? 'text' : 'textSubtle'}>
            {rank.points}
          </Text>
          */}
          <Balance
            fontSize="16px"
            color={rank.points > 0 ? 'text' : 'textSubtle'}
            textAlign={"right"}
            decimals={0}
            value={Number(rank.points)}
          />
          {hasNFT ? <NFTBoostedTag /> : <Flex style={{ width: '12px' }} />}
        </Box>
      </Td>
    </tr>
  )
}

export default RankCard