import { ChainId } from '@pancakeswap/sdk'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { Address, useContractRead } from 'wagmi'
import { useActiveChainId } from './useActiveChainId'

// Standard ERC721 ABI entries we need
const erc721ABI = [
  {
    inputs: [{ name: 'owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const

const useNFTBalance = (nftAddress: Address, userAddress: Address, forceChain?: ChainId) => {
  const { chainId } = useActiveChainId()

  const { data, status, ...rest } = useContractRead({
    chainId: forceChain ?? chainId,
    abi: erc721ABI,
    address: nftAddress,
    functionName: 'balanceOf',
    args: [userAddress],
    enabled: !!userAddress,
    watch: true,
  })

  return {
    ...rest,
    fetchStatus: status,
    balance: useMemo(() => (typeof data !== 'undefined' ? new BigNumber(data.toString()) : BIG_ZERO), [data]),
  }
}

// Example usage with specific NFT collections
export const useSpecificNFTBalance = (nftAddress: Address, userAddress: Address) => {
  const { balance, fetchStatus } = useNFTBalance(nftAddress, userAddress)
  const hasNFT = useMemo(() => balance.gt(0), [balance])

  return { balance: BigInt(balance.toString()), fetchStatus, hasNFT }
}

// Example for a specific chain
export const useBitgertNFTBalance = (nftAddress: Address, userAddress: Address) => {
  const { balance, fetchStatus, hasNFT } = useSpecificNFTBalance(nftAddress, userAddress)
  return { balance: BigInt(balance.toString()), fetchStatus, hasNFT }
}

export default useNFTBalance