import React from 'react'
import { styled } from 'styled-components'
import { Card, CardHeader, Box, Heading, Text, Balance, Flex } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import PrizesGrid from './PrizesGrid'
import { LeaderboardPrizes } from 'views/Leaderboard/types'
import { Currency } from '@pancakeswap/swap-sdk-core'
import TokenPriceDisplay from 'components/TokenPrice'

const StyledCard = styled(Card)`
  ${({ theme }) => theme.mediaQueries.md} {
    flex: 1;
  }
`

interface PrizesProps {
  chainId: number;
  prizes: LeaderboardPrizes;
  prizePool: {amount: number, token: Currency};
}

const LeaderboardPrizesCard: React.FC<PrizesProps> = ({ chainId, prizes, prizePool }) => {
  const { t } = useTranslation()

  return (
    <StyledCard>
      <Box px={24} pt={24}>
        <Heading scale="lg">
          <Flex alignItems={'flex-start'} mb="6px" gap='12px'>
            <Text fontSize={['24px', '24px', '32px']} lineHeight="110%" bold>
              {t('Prize Pot')}
            </Text>
            <Flex flexDirection={'column'} alignItems={'center'}>
              <TokenPriceDisplay amount={Number(prizePool.amount)} chainId={chainId} currency={prizePool.token}/>
              <Balance
                unit={` ${prizePool.token.symbol}`}
                mb="18px"
                fontSize="14px"
                color="textSubtle"
                textAlign={['center', 'center', 'left']}
                decimals={prizePool.token.decimals}
                value={Number(prizePool.amount)}
              />
            </Flex>
          </Flex>
        </Heading>
      </Box>
      <PrizesGrid prizes={prizes} />
      <Box p="24px">
        <Text color="textSubtle" fontSize="14px">
          {t(
            'Prizes will be distributed within 72 hours after the competition. Random NFTs will be allocated as additional prizes.',
          )}
        </Text>
      </Box>
    </StyledCard>
  )
}

export default LeaderboardPrizesCard
