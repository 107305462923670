import { Box, Flex, Text } from '@pancakeswap/uikit';
import { useMemo } from 'react';
import { ChainId } from '@pancakeswap/sdk';
import { useActiveChainId } from 'hooks/useActiveChainId';
import Banner from 'views/Leaderboard/components/UsersLeaderboard/Banner';
import MaintenanceMessage from 'views/Leaderboard/components/Maintance';
import React from 'react';
import { GetServerSideProps } from 'next';
import { REWARDS_API } from 'config/constants/endpoints';
import { Leaderboard } from './types';
import UsersLeaderboard from './components/UsersLeaderboard';
import { competitions } from './leaderboard.config';
import Footer from 'views/Home/components/Footer';
import SubMenu from './components/SubMenu';
import { useAccount } from 'wagmi';
import NFTBoost from './components/UsersLeaderboard/NFTBoost';

// Helper function to get current competition
export const getCurrentCompetition = (chainId: number) => {
  const now = new Date().getTime();
  return competitions.find(
    (competition) =>
      competition.chainId === chainId &&
      new Date(competition.startTime).getTime() <= now &&
      new Date(competition.endTime).getTime() >= now
  );
};

interface GlobalLeaderboardProps {
  initialData: Leaderboard;
}

const GlobalLeaderboard: React.FC<GlobalLeaderboardProps> = ({ initialData }) => {
  const { chainId } = useActiveChainId();
  
  const currentCompetition = useMemo(
    () => chainId ? getCurrentCompetition(chainId) : null,
    [chainId]
  );

  return (
    <Box>
      <Banner />
      <NFTBoost chainId={chainId} />
      <SubMenu />
      {(!currentCompetition || chainId !== ChainId.CRONOS)? (
        <Flex justifyContent={'center'} width={'100%'} py={50} px={3}>
          <Text>{'There are no active leaderboard rounds at the moment.'}</Text>
        </Flex>
      ):(
        <UsersLeaderboard
            chainId={chainId}
            startTime={currentCompetition.startTime}
            endTime={currentCompetition.endTime}
            prizes={currentCompetition.prizes}
            prizePool={currentCompetition.prizePool}
            initialData={initialData} />
      )}
      <Footer />
    </Box>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { chainId } = context.query;
  const currentCompetition = getCurrentCompetition(Number(chainId));
  const { address } = useAccount()

  if (!currentCompetition) {
    return {
      props: {
        initialData: [],
      },
    };
  }

  try {
    const response = await fetch(
      `${REWARDS_API}/${chainId}/v1/leaderboard?chainId=${chainId}&startTime=${
        currentCompetition.startTime
      }&endTime=${currentCompetition.endTime}&address=${address}`
    );
    
    if (!response.ok) {
      console.log('Failed to fetch leaderboard data');
    }
    
    const result = await response.json();
    const initialData = result.weeksData || [];

    return {
      props: {
        initialData,
      },
    };
  } catch (error) {
    console.error('Error fetching leaderboard:', error);
    return {
      props: {
        initialData: [],
      },
    };
  }
};

export default GlobalLeaderboard;