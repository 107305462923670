import {
  BlockIcon,
  Box,
  CheckmarkCircleIcon,
  MedalBronzeIcon,
  MedalGoldIcon,
  MedalPurpleIcon,
  MedalSilverIcon,
  MedalTealIcon,
  Td,
  Text,
} from '@pancakeswap/uikit'
import Trans from 'components/Trans'
import { Tiers } from 'config/constants/trading-competition/prizes'
import { useTranslation } from '@pancakeswap/localization'
import StyledPrizeTable, { BoldTd } from 'views/TradingContest/components/StyledPrizeTable'
import { LeaderboardPrizes } from 'views/Leaderboard/types'

const COLOR_GOLD = '#FFBF33'
const COLOR_SILVER = '#C1C1C1'
const COLOR_BRONZE = '#E79559'
const COLOR_PURPLE = '#A57CFD'
const COLOR_TEAL = '#4CD2DD'
const COLOR_PINK = '#FF69B4';
const COLOR_LAVENDER = '#E6E6FA';

const tierStyleMap = {
  [Tiers.GOLD]: {
    icon: MedalGoldIcon,
    label: {
      text: <Trans>Gold</Trans>,
    },
    color: COLOR_GOLD,
  },
  [Tiers.SILVER]: {
    icon: MedalSilverIcon,
    label: {
      text: <Trans>Silver</Trans>,
    },
    color: COLOR_SILVER,
  },
  [Tiers.BRONZE]: {
    icon: MedalBronzeIcon,
    label: {
      text: <Trans>Bronze</Trans>,
    },
    color: COLOR_BRONZE,
  },
  [Tiers.PURPLE]: {
    icon: MedalPurpleIcon,
    label: {
      text: <Trans>Purple</Trans>,
    },
    color: COLOR_PURPLE,
  },
  [Tiers.TEAL]: {
    icon: MedalTealIcon,
    label: {
      text: <Trans>Teal</Trans>,
    },
    color: COLOR_TEAL,
  },
  [Tiers.PINK]: {
    label: {
      text: <Trans>Pink</Trans>,
    },
    color: COLOR_PINK,
  },
  [Tiers.LAVENDER]: {
    label: {
      text: <Trans>Lavender</Trans>,
    },
    color: COLOR_LAVENDER,
  },
}

interface PrizesGridProps {
  prizes: LeaderboardPrizes
}

const PrizesGrid: React.FC<React.PropsWithChildren<PrizesGridProps>> = ({ prizes }) => {
  const { t } = useTranslation()

  return (
    <Box pt="6px">
      <Box minWidth="288px" overflowX="auto" maxWidth="100%">
        <StyledPrizeTable>
          <thead>
            <tr>
              <th>{t('Rank')}</th>
              <th>{t('Tier')}</th>
              <th>{t('Prizes (Split)')}</th>
              <th>{t('NFT')}</th>
            </tr>
          </thead>
          <tbody>
            {prizes.map((row) => {
              const { /*icon: Icon,*/ label, color } = tierStyleMap[row.tier]

              return (
                <tr key={row.rank}>
                  <BoldTd>{row.rank}</BoldTd>
                  <Td>
                    {/*<Icon />*/}
                    <Text color={color} fontSize="12px" bold textTransform="uppercase">
                      {label.text}
                    </Text>
                  </Td>
                  <BoldTd>
                    {`${row.prize.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}%`}
                  </BoldTd>
                  <Td>{row.hasNft ? <CheckmarkCircleIcon color="success" /> : <BlockIcon color="textDisabled" />}</Td>
                </tr>
              )
            })}
          </tbody>
        </StyledPrizeTable>
      </Box>
    </Box>
  )
}

export default PrizesGrid