import React, { memo } from 'react';
import { Currency } from '@pancakeswap/sdk';
import { Balance, Loading, Flex } from '@pancakeswap/uikit';
import { useDexScreenerPrice } from 'hooks/useBUSDPrice';

interface TokenPriceDisplayProps {
  currency: Currency;
  amount: number;
  chainId: number;
}

const TokenPriceDisplay = memo(function TokenPriceDisplay({
  currency,
  amount,
  chainId,
}: TokenPriceDisplayProps) {
  const { price: amountInDollar, isLoading } = useDexScreenerPrice(
    currency,
    Number.isFinite(amount) ? amount : 0,
    chainId
  );

  if (!amountInDollar) return 0;

  return (
    <Flex justifyContent="flex-end" mr="1rem">
      <Flex maxWidth="200px">
        {isLoading ? (
          <Loading width="14px" height="14px" />
        ) : Number.isFinite(amountInDollar) ? (
          <Balance
            prefix="~$"
            bold
            color="secondary"
            lineHeight="110%"
            fontSize={['24px', '24px', '32px']}
            decimals={0}
            value={amountInDollar}
          />
        ) : (
          <div style={{ height: "18px" }} />
        )}
      </Flex>
    </Flex>
  );
});

export default TokenPriceDisplay;