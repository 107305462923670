import { ReactNode, memo } from "react";
import { Heading } from "../../components/Heading";
import { AtomBox } from "../../components/AtomBox";
import { Text } from "../../components/Text";
import styled, { keyframes, css } from "styled-components";

interface Props {
  title: ReactNode;
  subtitle: ReactNode;
  isLoading?: boolean;
}

const gradientAnimation = keyframes`
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
`;

const SwitchHeading = styled(Heading)<{ isLoading: boolean }>`
  background-clip: text !important;
  -webkit-background-clip: text !important;
  background-image: linear-gradient(
    45deg,
    rgb(109 42 151) 0%,
    rgb(0 138 209) 33%,
    rgb(109 42 151) 66%,
    rgb(0 138 209) 100%
  );
  background-size: 300% 100%;
  line-height: 1.3;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background-position: ${({ isLoading }) => (isLoading ? '100% 0%' : '0% 0%')};
  
  /* Mobile font size */
  font-size: 12px;

  /* Font size for screens above 365px */
  @media screen and (min-width: 365px) {
    font-size: 16px;
  }

  /* Animation only runs when isLoading is true */
  animation: ${({ isLoading }) => isLoading ? css`${gradientAnimation} 2s ease infinite` : 'none'};
`;

export const CurrencyInputHeader = memo(({ title, subtitle }: Props) => {
  return (
    <AtomBox width="100%" alignItems="center" flexDirection="column" px="12px" py="16px" borderBottom="1" borderColor="backgroundAlt3">
      <AtomBox display="flex" width="100%" alignItems="center" justifyContent="space-between">
        {title}
      </AtomBox>
      {subtitle}
    </AtomBox>
  );
});

export const CurrencyInputHeaderTitle = memo(({ children, isLoading = false }: { children: ReactNode; isLoading?: boolean }) => (
  <SwitchHeading as="h2" isLoading={isLoading}>
    {children}
  </SwitchHeading>
));

export const CurrencyInputHeaderSubTitle = memo(({ children }: { children: ReactNode }) => (
  <Text color="textSubtle" fontSize="12px" textAlign="center">
    {children}
  </Text>
));