import { Currency } from '@pancakeswap/swap-sdk-core';
import axios from 'axios';
import { TRADING_API } from 'config/constants/endpoints';
import { generateToken } from 'strict/utils/jwtToken';
import { Address } from 'viem';

interface SwapDetails {
  valueOutUsd:number;
  txHash: string;
  chainId: number;
  inputToken: Currency;
  outputToken: Currency;
  inputAmount: string;
  outputAmount: string;
  recipient: Address;
}

export const sendSwapDetailsToEndpoint = async (swapDetails: SwapDetails) => {
    try {
      const token = await generateToken(); 
      // console.log('Sending Swap details', swapDetails )
      const response = await axios.post(`${TRADING_API}/trading`, swapDetails, 
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      return response.data;
    } catch (error) {
      console.error('Error sending swap details:', error);
      throw new Error('Failed to send swap details');
    }
  };