import useSWR from 'swr'
import { REWARDS_API } from 'config/constants/endpoints'
import { Leaderboard } from '../types'
import { Address } from 'viem'

interface UseAllTradingTokensProps {
  chainId: number
  startTime: string
  endTime: string
  address?: Address
  enabled?: boolean
}

const fetchLeaderboard = async (chainId: number, startTime: string, endTime: string, address?: Address) => {
  const url = new URL(`${REWARDS_API}/${chainId}/v1/leaderboard`)
  url.searchParams.set('chainId', chainId.toString())
  url.searchParams.set('startTime', startTime)
  url.searchParams.set('endTime', endTime)
  if (address) {
    url.searchParams.set('address', address)
  }
  
  const response = await fetch(url.toString())
  
  if (!response.ok) {
    throw new Error('Failed to fetch leaderboard')
  }
  
  const result = await response.json()
  return result as Leaderboard
}

const useLeaderboard = ({ chainId, startTime, endTime, address, enabled = true }: UseAllTradingTokensProps) => {
  const { data, error } = useSWR(
    enabled && chainId && startTime && endTime ? 
      [`/leaderboard/${chainId}`, startTime, endTime, address] : null,
    () => fetchLeaderboard(chainId, startTime, endTime, address),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    }
  )

  return {
    data: data || [],
    isLoading: !error && !data,
    isError: error,
  }
}

export default useLeaderboard