import React from 'react'
import Marquee from 'react-fast-marquee'
import { Flex, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { getCurrentCompetition } from 'views/Leaderboard'


// Styled components
const StyledMarquee = styled(Marquee)`
margin-bottom: 5px;
width: 100%;
max-width: 1063px;
mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
-webkit-mask-image: linear-gradient(to left, transparent, black 80px, black calc(100% - 80px), transparent);
`

interface NFTCollectionConfig {
  address: string;
  name: string;
  type: 'CORE' | 'COMMUNITY';
  boost: string
}

// List of supported NFT collections with their boost configurations
const NFT_COLLECTIONS: NFTCollectionConfig[] = [
  {
    address: '0x1dBA58B1bdcaA3B0488065cCEFa057c52a963280', // Existing CORE collection
    name: 'Robin NFT',
    type: 'CORE',
    boost: '10+'
  },
  {
    address: '0xd25358e2cAD3E1Fd165887569892A99fFFA674ac', // Cronos Cruisers
    name: 'Cronos Cruisers',
    type: 'COMMUNITY',
    boost: '25' // Flat 25% boost for any ownership
  }
  // Add more collections as needed
];

interface NFTBoostProps {
  chainId: number
}

export const NFTBoost: React.FC<NFTBoostProps> = ({ chainId }) => {
  const currentCompetition = getCurrentCompetition(Number(chainId));

  if (!currentCompetition) { 
    return null;
  }
  
  return (
    <StyledMarquee>
      {NFT_COLLECTIONS.map((token) => {
        return (
          <Flex key={token.address} alignItems="center" mr="24px" gap="3px">
            <Text fontSize="12px">{token.name}</Text>
            <Text fontSize="12px" color="success" ml="2px">
              {token.boost}% Boost
            </Text>
          </Flex>
        )
      })}
    </StyledMarquee>
  )
}

export default NFTBoost