export const timeFormat = (locale: string, time: number) => {
  if (!time) {
    return ''
  }

  return new Date(time * 1000).toLocaleString(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
}

export const formatDate = (dateString: string, showYear?: boolean) => {
  // Create a date object from the input string
  const date = new Date(dateString);

  // Define options for the desired format
  const options: Intl.DateTimeFormatOptions = {
    month: 'short', // Display the month as a short name (e.g., "Aug")
    day: 'numeric', // Display the day of the month as a number
    hour: 'numeric', // Display the hour
    minute: '2-digit', // Display the minute with leading zeros
    hour12: true, // Use 12-hour time format with AM/PM
  };

  // Include the year if showYear is true
  if (showYear) {
    options.year = 'numeric';
  }

  // Return the formatted date string
  return date.toLocaleString('en-US', options);
}
