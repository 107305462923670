import React, { useEffect, useState } from 'react';
import { formatDate } from 'views/Leaderboard/utils/timeFormat';
import {
  Text,
  Flex,
} from '@pancakeswap/uikit';
import { useTranslation } from '@pancakeswap/localization';
import useLeaderboard from 'views/Leaderboard/hooks/useLeaderboard';
import Traders from './Traders';
import { Leaderboard, LeaderboardPrizes } from 'views/Leaderboard/types';
import LeaderboardPrizesCard from './PrizesCard';
import { Currency } from '@pancakeswap/swap-sdk-core';
import { useAccount } from 'wagmi';

interface UsersLeaderboardProps {
  chainId: number;
  startTime: string;
  endTime: string;
  prizes: LeaderboardPrizes;
  prizePool: {amount: number, token: Currency};
  initialData?: Leaderboard;
}

const UsersLeaderboard: React.FC<UsersLeaderboardProps> = ({
  chainId,
  startTime,
  endTime,
  prizes,
  prizePool,
  initialData,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<Leaderboard>(initialData);
  const { address } = useAccount();
  
  const { data: fetchedData, isLoading } = useLeaderboard({
    chainId,
    startTime,
    endTime,
    address,
    enabled: Boolean(chainId && startTime && endTime),
  });

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData as Leaderboard);
    }
  }, [fetchedData]);

  return (
    <Flex
      id="rewards-breakdown"
      flexDirection="column"
      padding="0 16px"
      margin={['32px auto 72px auto', '32px auto 72px auto', '72px auto 72px auto', '24px auto 24px auto']}
      width={['100%', '100%', '100%', '100%', '100%', '100%', '1140px']}
    >
      {data && (
        <Text textAlign="center" color="textSubtle" bold>
          {t('%startTime% - %endTime%', {
            startTime: formatDate(startTime),
            endTime: formatDate(endTime),
          })}
        </Text>
      )}
      <Flex style={{ gap: 10 }} mt="40px" flexDirection={['column', 'column', 'column', 'column', 'row']}>
        <Flex width={['100%', '100%', '100%', '100%', '50%']} mb="10px" flexDirection="column">
          <Traders data={data?.topTraders || []} isLoading={isLoading} userData={data?.userData} />
        </Flex>
        <Flex width={['100%', '100%', '100%', '100%', '50%']} mb="10px" flexDirection="column">
          <LeaderboardPrizesCard prizes={prizes} chainId={chainId} prizePool={prizePool} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default UsersLeaderboard;